<template>
<Navbar user="0"/>
  <div class="error-404-area">
    <div class="container">
      <div class="notfound">
        <div class="notfound-bg">
          <div></div>
          <div></div>
          <div></div>
        </div>
        <h1>Oops!</h1>
        <h3>Error 404 : Page Not Found</h3>
        <p>
          The page you are looking for might have been removed had its name
          changed or is temporarily unavailable.
        </p>
        <router-link :to="{ name: 'Home' }" class="default-btn">
          <span class="label">Back Home</span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import axios from 'axios'
export default {
  components: { Navbar },
  data(){
    return {
      isLoading: true,
      token: localStorage.getItem('user_data'),
      user:{},
      authError: null
    }
  },
  methods:{
    getUser(){
      axios.get('https://apitraining.vipawaworks.com/api/user').then(response => {
          this.user = response.data
      }).catch(errors => {
         this.authError = errors.response.data.errors
      })
    },
  },
  created(){
    document.title = 'Not Found - Training'
    this.getUser()
  }
}
</script>

<style>

</style>